import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import IframeResizer from 'iframe-resizer-react'

import Layout from '../components/layout'
import Menu from '../components/menu'
import Seo from '../components/seo'
import Wordmark from '../../assets/wordmark.svg'

const BookCallPage = ({ active = `bookcall` }) => (
  <Layout>
    <Seo title="My Work and Well-Being | About Us" />
    <div className="max-w-[1200px] mx-auto">
      <div className="mx-auto px-6 py-6 lg:flex lg:items-baseline lg:justify-between lg:px-8 text-center h-auto">
        <Wordmark className="max-w-sm inline pb-14 lg:pb-0" />
        <Menu active={active} />
      </div>
    </div>
    <div className="h-60 bg-gray-800"></div>
    <div className="max-w-[1200px] mx-auto -mt-44">
      <p className="text-myblue text-3xl lg:text-4xl pb-4 pl-6 font-tbold">
        Book a Call
      </p>
      <StaticImage
        src="../images/help.jpg"
        alt="Decorative image of an overwhelmed individual"
        className="rounded-2xl m-6 lg:p-0"
      />
    </div>
    <section className="pt-12 max-w-[1200px] mx-auto bg-white">
      <div className="md:max-w-4xl px-8">
        <h3 className="text-mypurple text-2xl md:text-3xl mb-8">
          We are here to help!
        </h3>
        <h2 className="text-4xl md:text-5xl mb-12">Book a Call</h2>
      </div>
    </section>
    <div className="h-80 mx-auto inline">
      <IframeResizer
        title="iframe embed"
        src="https://calendly.com/myworkandwellbeing"
        checkOrigin={false}
        autoResize={true}
        scrolling={false}
        width="100%"
        style={{
          border: 0,
          width: `1px`,
          minWidth: `100%`,
          minHeight: `1000px`,
        }}
      />
    </div>
    <section>
      <div className="max-w-[1200px] mx-auto my-12">
        <StaticImage
          src="../images/image-presentation.jpg"
          alt="Decorative image showing the word work safety"
          className="h-full"
        />
      </div>
    </section>
  </Layout>
)

export default BookCallPage
